import React from 'react';
import { useLocation } from '@reach/router';
import IconMap from "../../images/icon--map.svg";
import TimeFive from "../../images/icon--timefive.svg";
import Vector61 from "../../images/vector-61.svg";

const AllVacanciesPage = () => {
  const location = useLocation();
  const vacancies = location.state?.vacancies || {};

  return (
    <div className="career-career-21">
      <div className="career-container3">
        <div className="career-section-title">
          <div className="career-subheading">All Vacancies</div>
        </div>
        <div className="career-content5">
          <div className="career-content6">
            {Object.keys(vacancies).map((category) => (
              vacancies[category].map((vacancy, index) => (
                <div key={index} className="career-card">
                  <div className="career-job">
                    <div className="career-content2">
                      <div className="career-job-title">
                        <div className="career-heading">{vacancy.title}</div>
                        <div className="career-tag">
                          <div className="career-link-one">{vacancy.department}</div>
                        </div>
                      </div>
                      <div className="career-button10">Apply Now</div>
                      <img className="career-content-child" alt="" src="/vector-7.svg" />
                      <div className="career-button11">
                        <div className="career-button-child" />
                        <div className="career-apply-now-parent">
                          <div className="career-apply-now">Apply Now</div>
                          <img className="career-group-item" alt="" src={Vector61} />
                        </div>
                      </div>
                      <div className="career-stylelink-smallfalse-dark">
                        <div className="career-link-one">Apply Now</div>
                        <img className="career-chevron-down-icon" alt="" src="/icon--chevronright.svg" />
                      </div>
                    </div>
                    <div className="career-text3">Learn More</div>
                  </div>
                  <div className="career-info">
                    <div className="career-content8">
                      <img className="career-icon-map" alt="Location" src={IconMap} />
                      <div className="career-text4">{vacancy.location}</div>
                    </div>
                    <div className="career-content8">
                      <img className="career-icon-map" alt="Contract Type" src={TimeFive} />
                      <div className="career-text4">{vacancy.contractType}</div>
                    </div>
                  </div>
                </div>
              ))
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllVacanciesPage;
