import React from "react"
import AllVacanciesPage from "../templates/Careers/allVacancy"
import Header from "../components/header"
import Footer from "../components/footer"

export default function AllVacancyPage() {
  return (
    <div>
      <Header />
      <AllVacanciesPage />
      <Footer />
    </div>
  )
}
